import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const Customers = Loadable(lazy(() => import('../pages/customers/Customers')));
export const CustomerCreateEditPage = Loadable(
  lazy(() => import('../pages/customers/CustomerCreateEditPage'))
);
export const AccountManagerPage = Loadable(
  lazy(() => import('../pages/customers/AccountManagerPage'))
);
export const Partners = Loadable(lazy(() => import('../pages/partners/Partners')));
export const PartnerCreateEditPage = Loadable(
  lazy(() => import('../pages/partners/PartnerCreateEditPage'))
);
export const PartnerAccountPage = Loadable(
  lazy(() => import('../pages/partners/PartnerAccountPage'))
);
export const PartnerProfilePage = Loadable(
  lazy(() => import('../pages/partners/PartnersProfilePage'))
);

export const GoogleWorkspace = Loadable(
  lazy(() => import('../pages/googleWorkspace/GoogleWorkspace'))
);
export const CustomerInfo = Loadable(
  lazy(() => import('../sections/@dashboard/googleWorkspace/WorkspaceCustomerInfo'))
);
export const Transactions = Loadable(lazy(() => import('../pages/googleWorkspace/Transactions')));

export const Users = Loadable(lazy(() => import('../pages/users/Users')));
export const UserNewEditPage = Loadable(lazy(() => import('../pages/users/UserNewEditPage')));

export const WhatsAppSettingsPage = Loadable(lazy(() => import('../pages/whatsApp/SettingsPage')));
export const WhatsAppTemplatesPage = Loadable(
  lazy(() => import('../pages/whatsApp/TemplatesPage'))
);
export const Rates = Loadable(lazy(() => import('../pages/whatsApp/Rates')));
export const PartnersWallet = Loadable(lazy(() => import('../pages/whatsApp/wallet/Partners')));
export const CustomersWallet = Loadable(lazy(() => import('../pages/whatsApp/wallet/Customers')));
export const WalletTransactions = Loadable(
  lazy(() => import('../pages/whatsApp/wallet/Transactions'))
);
export const FixDisplayName = Loadable(lazy(() => import('../pages/whatsApp/FixDisplayName')));

export const WhitelabelConfigPage = Loadable(
  lazy(() => import('../pages/whitelabel/WhitelabelConfigPage'))
);

export const CustomizeDemoForm = Loadable(
  lazy(() => import('../pages/whatsApp/demo/CostomizeDemo'))
);

export const ReportsPage = Loadable(lazy(() => import('../pages/reports/ReportsPage')));

export const ManageAccount = Loadable(lazy(() => import('../pages/ManageAccount')));
export const ProfilePage = Loadable(lazy(() => import('../pages/ProfilePage')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
